import React from "react";
import NextLink from "next/link";
import { AspectRatio, Box, Flex, Link } from "@chakra-ui/react";

type Props = {
  href: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
};

const Tile: React.FC<Props> = ({ href, children, icon }) => (
  <Box position="relative">
    <AspectRatio ratio={1}>
      <Link as={NextLink} href={href} passHref legacyBehavior>
        <Box
          as="a"
          flexDirection="column"
          bg="gray.100"
          height={80}
          p={6}
          textAlign="center"
          lineHeight={1.2}
          transition="background-color 150ms ease-in-out"
          borderRadius={10}
          _hover={{
            bg: "gray.200",
          }}
        >
          {icon && (
            <Flex flexGrow={1} alignItems="center" justifyContent="center">
              {icon}
            </Flex>
          )}

          {children}
        </Box>
      </Link>
    </AspectRatio>
  </Box>
);

export default Tile;
