import { InfoIcon } from "@chakra-ui/icons";
import { AspectRatio, Box, Flex } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick: VoidFunction;
};

const Tile: React.FC<Props> = ({ children, icon, onClick }) => (
  <Box position="relative">
    <AspectRatio ratio={1}>
      <Box
        as="button"
        flexDirection="column"
        bg="white"
        height={80}
        p={6}
        textAlign="center"
        fontWeight="bold"
        lineHeight={1.2}
        transition="all 150ms ease-in-out"
        borderRadius={10}
        _hover={{
          transform: "translateY(-3px)",
        }}
        onClick={onClick}
      >
        {icon && (
          <Flex as="span" flex="1 1 auto" alignItems="center" justifyContent="center" mb={1}>
            {icon}
          </Flex>
        )}

        <InfoIcon
          as="span"
          position="absolute"
          top={5}
          right={5}
          h="20px"
          w="20px"
          color="blue.500"
        />

        {children}
      </Box>
    </AspectRatio>
  </Box>
);

export default Tile;
