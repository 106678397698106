import { Box, Button, Flex, Heading, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import { NextPage } from "next";
import Image from "next/image";
import { useSession } from "next-auth/react";
import { useCallback, useState } from "react";
import Layout from "@/components/Layout";
import Loading from "@/components/Loading";
import Welcome from "@/ui/pages/index/Welcome";
import { endpoints } from "@/constants";
import { NavigationLink } from "@/types/navigation";
import RemoveModal from "@/ui/pages/index/RemoveModal";
import Tile from "@/ui/pages/index/Tile";
import useSWR from "swr";

const RootPage: NextPage = () => {
  const { data: session, status } = useSession();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const { data: navigationLinks = [] } = useSWR<NavigationLink[]>(endpoints.getNavigationLinks);

  const isLoading = status === "loading";

  const onDeleteData = useCallback(
    async (selections: string[]) => {
      setShowDeleteModal(false);
      setIsDeleting(true);

      if (selections.includes("local-storage")) {
        localStorage.clear();
      }

      await fetch(endpoints.deleteAllData, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selections),
      });

      toast({
        title: "Data deleted",
        position: "top",
        status: "success",
        duration: 8000,
        isClosable: true,
      });

      setIsDeleting(false);
    },
    [toast],
  );

  if (isLoading) {
    return (
      <Box position="fixed" top={0} left={0} zIndex={10} height="100%" width="100vw">
        <Loading />
      </Box>
    );
  }

  if (!session) {
    return <Welcome />;
  }

  const homepageLinks = navigationLinks.filter(item => item.homepage?.show && item.homepage.image);

  return (
    <Layout>
      <Flex flexDirection={["column", null, null, "row"]} gap={10} justifyContent="space-between">
        <SimpleGrid
          flexGrow={1}
          columns={[2, null, 3]}
          spacing={6}
          maxWidth={720}
          fontWeight="bold"
        >
          {homepageLinks.map(({ title, destination, homepage }) => (
            <Tile
              key={title}
              href={destination}
              icon={
                homepage?.image && (
                  <Box w={homepage.width && `${homepage.width}%`}>
                    <Image
                      src={homepage.image}
                      alt=""
                      width={137}
                      height={171}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </Box>
                )
              }
            >
              {title}
            </Tile>
          ))}
        </SimpleGrid>

        <Box
          flex={[null, null, null, "0 1 350px"]}
          alignSelf="flex-start"
          p={[5, null, 8]}
          bg="gray.50"
          borderRadius={10}
        >
          <Heading as="h2" size="sm" mb={2}>
            Your data
          </Heading>

          <Text fontSize="sm" mb="1em">
            This site stores the minimum information needed for a fantastic studying experience.
            Your information is never shared or used for any other purpose.
          </Text>
          <Text fontSize="sm" mb={4}>
            Use the button below to delete all the stored data linked to your account.
          </Text>

          <Button isLoading={isDeleting} colorScheme="red" onClick={() => setShowDeleteModal(true)}>
            Delete all my data
          </Button>

          <RemoveModal
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={onDeleteData}
          />
        </Box>
      </Flex>
    </Layout>
  );
};

export default RootPage;
