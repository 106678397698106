import { ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import Link from "next/link";
import { signIn } from "next-auth/react";
import { isDesktop } from "react-device-detect";
import popupCenter from "@/server/popup-center";
import Logo from "@/components/Layout/Header/Logo";
import theme from "@/theme";
import Benefits from "./Benefits";

const Welcome: React.FunctionComponent = () => (
  <>
    <Global
      styles={css`
        body {
          background-color: ${theme.colors.gray[100]};
        }
      `}
    />

    <Flex flexDirection={["column", null, null, "row"]}>
      <Box
        bg="#023648"
        py={6}
        minHeight={[null, null, null, "100vh"]}
        w={[null, null, null, "30%"]}
        minWidth={[null, null, null, 500]}
        maxWidth={[null, null, null, 600]}
      >
        <Box position="sticky" top={0} left={0} pt={[6, null, null, 20]}>
          <Flex justifyContent="center" mb={[3, null, null, 12]}>
            <Logo />
          </Flex>

          <Box mx="auto" w="fit-content" maxWidth={1600}>
            <Flex gap={[0, null, null, 10]} alignItems="center" flexDirection="column">
              <Box textAlign="center" p={5}>
                <Text color="white" fontSize={[18, null, null, 22]}>
                  Log in with Patreon to access the
                  <br />
                  members area
                </Text>

                <Button
                  onClick={() => {
                    if (isDesktop) {
                      popupCenter("/sign-in", "Patreon sign in");
                    } else {
                      signIn("patreon");
                    }
                  }}
                  w="300px"
                  size="lg"
                  colorScheme="blue"
                  px="60px"
                  my={4}
                >
                  Log in with Patreon
                  <ExternalLinkIcon position="relative" top="-1px" ml={2} h={4} w={4} />
                </Button>

                <Flex
                  alignItems="center"
                  justifyContent="center"
                  maxWidth="max-content"
                  gap={2}
                  mx="auto"
                  mt={1}
                >
                  <InfoIcon color="white" />
                  <Link href="/troubleshooting" passHref legacyBehavior>
                    <Text as="a" fontSize={16} color="white" textDecoration="underline">
                      Help, I can't log in
                    </Text>
                  </Link>
                </Flex>
              </Box>

              <Box textAlign="center" p={5} borderRadius={15}>
                <Text fontSize={[14, 16, null, 18]} color="white">
                  Not already a member of <strong>Zero to Finals Patreon</strong>?
                </Text>
                <Button
                  as="a"
                  href="https://www.patreon.com/zerotofinals"
                  target="_blank"
                  rel="noreferrer"
                  size={["md", "lg"]}
                  variant="outline"
                  colorScheme="blue"
                  color="white"
                  w={[null, "200px"]}
                  mt={4}
                  _hover={{
                    bg: "rgba(255, 255, 255, 0.1)",
                  }}
                >
                  Join here
                  <ExternalLinkIcon position="relative" top="-1px" ml={2} h={4} w={4} />
                </Button>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>

      <Box flexGrow={1} p={[5, 10, null, null, null, 20]} overflowY="auto">
        <Benefits />
      </Box>
    </Flex>
  </>
);

export default Welcome;
