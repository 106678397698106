import {
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

const features = [
  ["how-to-learn", "How to Learn", "Completed lessons"],
  ["flashcards", "Flashcards", "Pinned flashcards"],
  ["fact-trainer", "Fact Trainer", "Responses"],
  ["saq", "SAQ", "Answers"],
  ["mcq", "MCQ", "Answers"],
  ["emq", "EMQ", "Answers"],
  ["tracking", "Tracking", "Saved exams"],
  ["osce", "OSCE Practice", "Saved scores"],
  [
    "local-storage",
    "Local storage",
    "Temporary browser data which is stored on your computer, for example: active category selections",
  ],
];

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: (selections: string[]) => void;
};

// TODO: abstract @/components/RemoveModal to allow for this use case?
const RemoveModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const [checkedItems, setCheckedItems] = useState<string[]>(features.flatMap(([id]) => id));

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent m={16}>
        <ModalHeader>Delete all data</ModalHeader>

        <ModalBody>
          <Stack divider={<Divider />}>
            {features.map(([id, label, sublabel]) => (
              <Checkbox
                key={id}
                value={id}
                isChecked={checkedItems.includes(id)}
                onChange={e =>
                  setCheckedItems(items =>
                    e.currentTarget.checked ? [...items, id] : items.filter(item => item !== id),
                  )
                }
                spacing={3}
              >
                {label}
                <Text as="span" display="block" fontSize="xs">
                  {sublabel}
                </Text>
              </Checkbox>
            ))}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>

          <Button colorScheme="red" onClick={() => onConfirm(checkedItems)} ml={3}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemoveModal;
